<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
// import PageHeader from '@components/page-header'
// import axios from '@/axios'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Contents',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    //  PageHeader
  },
  data() {
    return {
      title: 'Contents',
      name: '',
      nameState: null,
      submittedNames: [],
      dataVideo: [
        {
          id: 1,
          title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
          url: require('@assets/images/mock/video1.png'),
        },
        {
          id: 2,
          title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
          url: require('@assets/images/mock/video2.png'),
        },
      ],
    }
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // await axios.post('/api/')

      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    },
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Category Course Video</h4>
      </div>
      <div class="col-md-3 col-xl-6">
        <router-link to="/category/courses/video/create">
          <button
            class="btn btn-primary rounded-pill width-lg float-right aligncenter"
          >
            <feather type="file-plus"></feather> Create
          </button>
        </router-link>

        <b-modal
          id="modal-prevent-closing"
          ref="modal"
          title="Create Sub Course"
          @show="resetModal"
          @hidden="resetModal"
          @ok="handleOk"
        >
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group
              :state="nameState"
              label="Name"
              label-for="name-input"
              invalid-feedback="Name is required"
            >
              <b-form-input
                id="name-input"
                v-model="name"
                :state="nameState"
                required
              ></b-form-input>
            </b-form-group>
          </form>
        </b-modal>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Thumbnail</th>
                    <th scope="col">Title</th>
                    <th scope="col"></th>
                    <th scope="col" class="thcenter">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(items, index) in dataVideo" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td><img :src="items.url" :alt="items.title"/></td>
                    <td>{{ items.title }}</td>
                    <td> </td>
                    <td class="action">
                      <button class="btn btn-info style-button">
                        <feather type="eye"></feather>
                      </button>
                      <button class="btn btn-warning style-button">
                        <feather type="edit"></feather>
                      </button>
                      <button class="btn btn-danger style-button">
                        <feather type="delete"></feather>
                      </button>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td>2</td>
                    <td
                      >Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.</td
                    >
                    <td></td>
                    <td> </td>
                    <td>
                      <button class="btn btn-info p-0 px-2 py-1 mr-2"
                        >View</button
                      >
                      <button class="btn btn-warning p-0 px-2 py-1 mr-2"
                        >Edit</button
                      >
                      <button class="btn btn-danger p-0 px-2 py-1 mr-2"
                        >Delete</button
                      >
                    </td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
